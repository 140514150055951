

*, html {
  margin:0;
  padding:0;  

}
html, body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
.SignUp{
    background-color: blue;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}
.SignUp h1{
  font-size: 3em;
}

.box{
    border-radius: 1em;
    margin: 0em;
    animation: fadeIn 1s;
    padding: 2em;
    border: solid 3px white;
    width: 30vw;
}

.box h1 {
    color: rgb(255, 255, 255);
    font-family: "Hoefler Text";
    font-size: 2em;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    padding: .5em;
    line-height: 1.2em;
    width: 100%;
    padding: 0em;
    padding-bottom: 1em;

}


.options{
    display: flex;
    flex-wrap: wrap;
    
}

.options p{
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    text-align: center;
    width: 100%;
    background-color: blue;
}
.options p:hover{
    text-decoration: line-through;
}

.fade-in-text {
    font-family: Arial;
    font-size: 60px;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

/* THIS CHANGES INPUT */
  .question input{
    border: none;
    border-bottom: solid 2px white;
    opacity: 1; 
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    width: auto;
    animation: none;
    width: 100%;
    padding: 0em;
    text-align: center;
    padding-bottom: 1em;
    color: white;
  }


  .question textarea:focus, .SignUp input:focus{
    outline: none;
    color: white;
    opacity: 1;
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    text-align: center;
    font-style: normal;
    animation: none;
    background-color: blue;
}

.question::placeholder {
  color: white;
    opacity: 0;
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    text-align: center;
    font-style: normal;
    animation: none;
    background-color: blue;
  }

#question2{
  background-color: none;
  min-width: 100%;
}
  .SignUp h1{
    font-family: "Hoefler Text";
    font-size: 2em;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    animation: fadeIn 3s;
  }


  .SignUp p { 
    color: white;
    opacity: 1;
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    text-align: center;
    font-style: normal;
    
  }
  .SignUp button:focus{
    text-decoration: line-through;
    font-weight: 900;
    font-size: 1em;
  }


  .SignUp button{
    background-color: blue;
    border: none;
    color: white;
    opacity: 1;
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    text-align: center;
    font-style: normal;
    flex: 50%;
    padding: .75em;
    border-radius: .8em;
  }

  .done{
    margin-top: 2em;
    text-align: center;
    animation: fadeIn 3s;
    text-decoration: none;
    flex: 100%;
    box-sizing: border-box;
  }

  .done button{
    margin-top: 0em;
    text-align: center;
    text-decoration: none;
    color: blue;
    background-color: white;
    width: auto;
    border: solid 2px blue;

  }
  .done button:hover{
    text-decoration: none;
    color: white;
    background-color: blue;
    border: solid 2px white;
  }


  .SignUp input,   .SignUp select,   .SignUp textarea{
    background-color: blue;
}
#question2 {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*finally found way to change placeholder*/
#question2 input::placeholder{
  text-align: center;
  color: white;
  opacity: 1;
}

/*this will change width of input*/
#question2 input{

  width: auto;
  background-color: none;
}


.question input{
  width: 100%;
  color: white;
}
.question input::placeholder{
  color: white;
}

.question button{
  background-color: white;
  color: blue;
  border-radius: .8em;
  padding: .5em;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
}
.question button:active{
  background-color: blue;
  color: white;
  border: solid white 3px;
}

@media screen and (max-width: 800px)
{

    html, body { 
      margin: 0;
      padding: 0;
        overscroll-behavior-y: auto;
    }
    #root{
      margin: 0;
      padding: 0;
  }
    .box{
      width: auto;
      justify-items: center;
      align-items: center;
      justify-content: center;
      margin: 2em;
    }

    .SignUp h1{
      font-size: 3em;
      padding-bottom: 1em;
    }
    #question2{
      font-size: 1em;
      padding: 0em;
      margin: 0;
      text-align: center;
    }
    .question input {
      background-color: transparent;
      border: none;
      border-bottom: solid 2px white;
      color: white;
      font-family: "Andale Mono", AndaleMono, monospace;
      font-size: 1em;
      font-weight: light;
      width: 100%;
      padding: 0;
      text-align: center;
      padding-bottom: 1em;
    }
    
    .question input, .SignUp input {
      background-color: transparent !important;
      background: none !important;
      border: none !important;
      border-bottom: solid 2px white !important;
      color: white !important;
      font-family: "Andale Mono", AndaleMono, monospace !important;
      font-size: 1em !important;
      font-weight: light !important;
      width: 100% !important;
      padding: 0 !important;
      text-align: center !important;
      padding-bottom: 1em !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
    }
    
   .question input:focus, .SignUp input:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    
    /* Update placeholder color */
.question input::placeholder, .SignUp input::placeholder {
      color: rgba(255, 255, 255, 0.7) !important;
    }

    .question button{
      background-color: white;
      color: blue;
      border-radius: .8em;
      padding: .5em;
      font-size: 20px;
      font-weight: 900;
      text-transform: uppercase;
      width: auto;
    }
    .question button:active{
      background-color: blue;
      color: white;
      border: solid white 3px;
    }

    .box{
      border-radius: 1em;
      margin: 0em;
      animation: fadeIn 1s;
      padding: 2em;
      border: none;
      max-width: 100vw;
  }

}

  select{
    text-align: center;
    color: white;
    background-color: blue;
    border: solid white 2px;
    border-radius: 1em;
    padding: .3em;
    padding-left: 2em;
    padding-right: 2em;
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    text-align: center;
  }

.mid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}

form{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
/*
input {
  background-color: transparent;
  border: none;
  outline: none;
  color: white; 
}


input:focus {
  outline: none;
  box-shadow: none;
}
*/

/* Styles for autofilled inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent !important;
    caret-color: white !important; /* This makes the text cursor white */
}

/* Firefox specific autofill styles */
input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent !important;
    caret-color: white !important; /* This makes the text cursor white */
}

/* Additional general input styles */
.question input, .SignUp input {
    background-color: transparent !important;
    background: none !important;
    border: none !important;
    border-bottom: solid 2px white !important;
    color: white !important;
    font-family: "Andale Mono", AndaleMono, monospace !important;
    font-size: 1em !important;
    font-weight: light !important;
    width: 100% !important;
    padding: 0 !important;
    text-align: center !important;
    padding-bottom: 1em !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    caret-color: white !important; /* This makes the text cursor white for all inputs */
}