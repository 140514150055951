html, body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: hidden;
}
.mainer{
    width: 100vw;
    height: 100vh;
    background-color: blue;
    text-transform: uppercase;
    color: white;
    overflow-y: scroll;
    overflow-x: hidden;
}
.topbar {
	line-height: .5em;
	background-color: blue;
	color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    padding: 1em;
}
.topbar p {
	font-family: "Andale Mono", AndaleMono, monospace;
	font-size: .7em;
	font-weight: light;
    display: inline-block;
    padding-left: 100%;
    animation: marquee 40s linear infinite;
    font-style: normal;
}
@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

.logo{
    display: block;
    text-align: center;
    background-color: blue;
    margin-top: 0em;
}
.logo h1{
    font-family: "Hoefler Text";
    font-size: 2em;
    font-weight: 500;
    font-style: italic;
    display: inline-block;
    text-align: center;
    margin: .3em;
    color: white;
}
.logo h1:hover{
    opacity: .8;
}

.logo button{
    background-color: white;
    color: blue;
    font-family: "Andale Mono", AndaleMono, monospace;
    text-transform: uppercase;
    font-size: .6em;
    font-weight: light;
    border-radius: .3em;
    border: none;
    padding: .5em;
    float: right;
    margin-right: 3em;

}

.logo button:hover{
background-color: rgb(17, 0, 255);
color: rgb(255, 255, 255);
border: white solid 1px;
}

.start{
    border-top: solid white 1px;
    padding: 2em;
    border-bottom: solid white 1px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}
.start-child{
    flex: 50%;
    text-align: left;
}

.start-img{
    flex: 50%;
    text-align: right;
}
.start-img > img{
    max-height: 25vw;
}

.start-child > h1{
    font-family: "Hoefler Text";
    font-size: 2em;
    font-weight: 500;
    font-style: normal;
    text-align: left;
}

.start-child ul{
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    text-align: left;
    font-style: normal;
}
.start-child ul{
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    text-align: left;
    font-style: normal;
}
.conflict-child li{
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    text-align: right;
    font-style: normal;
}
.solution-child li{
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    text-align: left;
    font-style: normal;
}
.footer-child h1{
        font-style: normal;
}
.footer-child h2{
    font-style: normal;
}
ul {
    list-style-type: none;
    line-height: 2em;
    color: white;
  }

li a{
    text-decoration: none;
    color: white;
}

li > a:hover{
    text-decoration: line-through;
}
.conflict{

    padding: 2em;
    border-bottom: solid white 1px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.conflict-child{
    flex: 50%;
    text-align: right;
}

.conflict-img{
    flex: 50%;
    text-align: left;
}
.conflict-img > img{
    max-height: 25vw;
}

.conflict-child > h1{
    font-family: "Hoefler Text";
    font-size: 2em;
    font-weight: 500;
    font-style: normal;
}

.conflict-child > ul{
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    margin-left: 3em;
}

.solution{

    padding: 2em;
    border-bottom: solid white 1px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}
.solution-child{
    flex: 50%;
    text-align: left;
}

.solution-img{
    flex: 50%;
    text-align: right;
}
.solution-img > img{
    max-height: 30vw;
}
.solution-child > h1{
    font-family: "Hoefler Text";
    font-size: 2em;
    font-weight: 500;
    font-style: normal;
}

.solution-child > ul{
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
}
.solution-child  button{
    margin: 2em;
    background-color: white;
    color: blue;
    font-family: "Andale Mono", AndaleMono, monospace;
    text-transform: uppercase;
    font-size: .75em;
    font-weight: light;
    border-radius: .3em;
    border: none;
    padding: .5em;
    width: auto;
}



.solution-child button:hover{
background-color: rgb(17, 0, 255);
color: rgb(255, 255, 255);
outline: solid 1px white;
}

.footer{
    width: 100%;
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    margin: 1em;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
    border: none;
}
.footer-child > h1:hover{
    text-decoration: line-through;
}
.footer-child{
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}

.footer-child h1{
    flex: 30%;
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: .75em;
    font-weight: light;
    padding-bottom: .5em;
}
.footer h2{
    padding: .7em;
    text-align: center;
    font-size: .6em;
    font-style: normal;
}

@media screen and (max-width: 600px)
{
    html, body { 
        padding: 0;
        margin: 0;
    }

    #root{
        margin: 0;
        padding: 0;
    }
    .topbar{
        margin: 0;
        padding: 1em;
    }
    .logo{
        padding: 0;
        margin: 0;
    }
    .logo h1{
        text-align: center;
        width: auto;
        font-size: 1.5em;
        margin: 0;
        padding: .5em;
    }
    .logo button{
        margin: 0;
        float: right;
    }
     /* This is a single-line comment *//* This is a single-line comment */


    /* This is a single-line comment *//* This is a single-line comment */
   
    .start{
        max-width: 100%;
        height: auto;
        padding: 1em;
        text-align: center;
        border-top: solid white 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .start-child{
        flex: 50%;
        text-align: center;
        margin-bottom: 1em;
        max-width: 100%;
        height: auto;
    }

    .start-img{
        flex: auto;
        margin: 0;
        text-align: center;
        height: auto;
        max-width: 100%;
        padding-top: .5em;
        margin-bottom: 1em;
    }
    .start-img img{
        max-width: 100%;
        min-height: 30vh;
        padding-bottom: 1em;
    }

    .start-child h1{
        padding: 0;
        margin: 0;
        font-size: 3em;
        text-align: center;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .start-child ul{
        padding: 0;
        margin: 0;
        font-size: 1em;
        text-align: center;
    }
    .start-child li{
        padding: .5em;
        margin: 0;
        font-size: 1em;
        text-align: center;
    }


    

    .conflict{
        max-width: 100vw;
        height: auto;
        padding: 1em;
        text-align: center;
        border-top: solid white 2px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .conflict-child{
        text-align: center;
        margin-bottom: 1em;
        max-width: 100%;
        height: auto;
    }
    
    .conflict-img{
        padding-top: 2em;
        margin: 0;
        text-align: center;
        height: auto;
        max-width: 100%;
        
    }
    .conflict-img img{
        max-width: 100%;
        min-height: 30vh;
    }
    
    .conflict-child h1{
        font-size: 2.5em;
        text-align: center;
        padding-top: .5em;
        padding-bottom: .5em;
        margin: 0;
        margin: .5em;
        max-width: 80vw;
    }
    
    .conflict-child ul{
        font-size: 1em;
        text-align: center;
        padding: 0;
        margin: 0;
        padding-bottom: .5em;
 
    }
    .conflict-child li{
        font-size: 1em;
        text-align: center;
        padding: .5em;
        margin: 0;
    }

    /* This is a single-line comment *//* This is a single-line comment */








    /* This is a single-line comment *//* This is a single-line comment */

    .solution{
        border-top: solid white 2px;
        max-width: 100%;
        height: auto;
        padding: 1em;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .solution-child{
        text-align: center;
        margin-bottom: 1em;
        max-width: 100%;
        height: auto;
        flex: 100%;
    }
    
    .solution-img{
        margin: 0;
        text-align: center;
        height: auto;
        max-width: 100%;
        flex: 100%;
        padding-top: .5em;
        padding-bottom: 1em;
    }
    .solution-img > img{
        max-width: 100%;
        min-height: 40vh;
    }
    .solution-child h1{
        font-size: 3em;
        text-align: center;
        margin: .5em;
        padding-top: .5em;
        padding-bottom: .1em;
    }
    
    .solution-child ul{
        font-size: 1em;
        text-align: center;
        padding: 0;
        margin: 0;
    }
    .solution-child li{
        font-size: 1em;
        text-align: center;
        padding: .5em;
        margin: 0;
    }
    .solution-child > button{
        margin: 3em;
        font-size: 1em;
    }

    /* This is a single-line comment *//* This is a single-line comment */
    /* This is a single-line comment *//* This is a single-line comment */

    .footer{
        border-top: solid rgb(255, 255, 255) 2px;
        max-width: 100%;
        height: auto;
        margin: 0;
        text-align: center;
    }

    .footer-child{
        text-align: center;
        max-width: 100%;
        height: auto;
    }
    
    .footer-child > h1{
        padding-top: .5em;
        flex: 30%;
        font-size: .65em;
    }
    .footer > h2{
        text-align: center;
        font-size: .5em;
        margin-bottom: .5;
    }

}

/* Hide scrollbar for Chrome, Safari and Opera */
.mainer::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.mainer{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/* Chrome, Safari, Edge, Opera to remove arrows from input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}