.Result{
    background-color: none;
    max-width: 100%;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-content: center;
	font-family: "Helvetica";
    font-weight: 100;
    font-style: normal;
    text-transform: capitalize;
    flex-wrap: nowrap;
    padding: 1.5em;
    margin: 0;
    color: white;
    background-color: none;
    border-top: solid white 6px;
    margin-top: 0em;
    border-radius: .8em;
}

.left{
    flex: 40%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0em;
    text-transform: uppercase;
    height: auto;
}
.left p1{
    font-family: "Hoefler Text";
    font-size: auto;
    font-weight: 900;
    font-style: italic;
    text-decoration: none;
    flex: 100%;
}
.left p2{
    flex: 100%;
    font-size: .5em;
}

/*right side info*/
.right{
    flex: 60%;
    text-align: center;
    padding-right: 0em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

/* just calories */
.resultCalories{
    flex: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    flex-wrap: nowrap;
    margin: .4em;
}
.resultCalories p1{
    flex: 90%;
    font-size: .7em;
    font-weight: 900;
    text-align: right;
    padding-right: 1em;
}
.resultCalories p2{
    flex: 10%;
    font-size: .8em;
    text-align: right;
}

/* carbs, prottein, fats */
.resultMacro{
    flex: 100%;
    text-align: center;
    font-size: .5em;
    display: flex;
    flex-wrap: nowrap;
    justify-content: right;
    align-items: center;
    flex-wrap: nowrap;
}

.resultCarb{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.resultCarb p1{
    font-size: .5em;
    font-weight: 900;
}
.resultCarb p2{
    flex: 100%;
    font-size: .5em;
}

.resultProtein{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.resultProtein p1{
    font-size: .5em;
    font-weight: 900;
}
.resultProtein p2{
    flex: 100%;
    font-size: .5em;
}

.resultFat{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.resultFat p1{
    font-size: .5em;
    font-weight: 900;
}
.resultFat p2{
    flex: 100%;
    font-size: .5em;
}




@media screen and (max-width: 800px)
{

    html, body { 
    margin: 0;
    padding: 0;
    zoom: 1;
    }
    #root{
        margin: 0;
        padding: 0;
    }

    .Result{
    max-width: 100vw;
    flex-wrap: nowrap;
    padding: 1em;
    max-height: auto;
    gap: 1em;
    justify-content: center;
    align-items: center;
    margin-bottom: .5em;
    margin-top: .5em;

    }
    .left{
        padding: 0;
        max-width: 30vw;
        gap: 0;
        max-height: auto;
        justify-content: center;
        align-items: center;

    }

    .left p1{
        font-size: 1.5em;

    }
    .left p2{
        font-size: .5em;

    }

    .right{
        padding: 0;
        padding-bottom: 1em;
        max-width: 70vw;
        gap: 0;

    }

    .resultCalories p1{
        font-size: .8em;
    }
    .resultCalories p2{
        font-size: .8em;
    }

    .resultCarb p1{
        font-size: .5em;
        font-weight: 500;
    }
    .resultCarb p2{
        font-size: .2em;
    }

    .resultProtein p1{
        font-size: .5em;
        font-weight: 500;
    }
    .resultProtein p2{
        font-size: .2em;
    }

    .resultFat p1{
        font-size: .5em;
        font-weight: 500;
    }
    .resultFat p2{
        font-size: .2em;
    }



}