
.Search{
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    background-color: none;
}



.container{
    background-color: none;
    width: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    box-sizing: content-box;

}



*:focus {
    outline: none;
}

/*one above */
.recents{
    height: 100%;
    border: none;
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    font-style: normal;
    color: rgb(0, 0, 0);
    background-color: none;
    box-sizing: content-box;
    padding: 1em;
    border-radius: 1em;
    border-radius: 1em;
    width: 30vw;
}

.recents p{
    color: black;
    font-size: .75em;
    padding: .5em;
}

.recents p:hover{
    color: rgb(172, 172, 172);
    font-size: .75em;
    padding: .5em;
}

#one{
    padding-top: 2em;
}




@media screen and (max-width: 700px)
{
    html, body { 
        padding: 0;
        margin: 0;
    }


    .searchBar{
        min-width: 60vw;
        max-height: 1vh;

    }
    /*
    .searchBar input{
        min-width: 50vw;
        min-height: auto;
        margin-right: .5em;

    }

    .recents{
        min-width: 70vw;
    }
    .UPC{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 9vw;
    }

    */
}

.searchBar {
    display: flex;
    align-items: center; /* Vertically aligns items */
    gap: .5em; /* Space between input and barcode button */
    background-color: blue;
    border-radius: 0%;
    color: white;
    text-align: center;

}

#searchInput {
    flex: 1; /* This makes the input take up remaining space */
    padding: .5em; /* Add padding to input for spacing */
    background-color: blue;
    border: 0;
    border-bottom: solid white 3px;
    color: white;
    text-align: center;
    font-family: "Andale Mono", AndaleMono, monospace;

}

#searchInput::placeholder {
    color: white;
    text-transform: uppercase;
}



.UPC {
    cursor: pointer; /* Makes it clear it's clickable */
    padding: 0px; /* Adds spacing around the icon */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: none; /* Background color for the barcode button */
    border-radius: 0px; /* Rounded edges for the button */
    color: white;
    font-size: 2em;
}

.UPC button:hover{
    color: darkgrey;
}

.loading{
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 1em;
    font-weight: light;
    font-style: normal;
    color: white;
    background-color: none;
    display: flex;
    align-items: center; /* Vertically aligns items */

}

/*



the one underneath
.searchBar{

    font-size: 16px;
    font-weight: light;
    font-style: italic;
    color: blue;
    background-color: none;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .75em;
    border-radius: 1em;
    border: none;
    max-width: 30vw;
    min-width: 30vw;
    background-color: red;
    color: black;
    max-height: 1vh;

}


.searchBar input{
    max-width: 25vw;
    min-width: 25vw;
    height: 100%;
    border: none;
    font-family: "Andale Mono", AndaleMono, monospace;
    font-size: 16px;
    font-weight: light;
    font-style: normal;
    box-sizing: content-box;
    margin-right: 1em;
    color: blue;
    background-color: black;
}

.searchBar input::placeholder{
    
color: blue;
}

.UPC{
    display: flex;
    justify-content: center;
    align-items: center;
    color: blue;
    font-size: 20px;
    background-color: none;
}
.searchBar input:active{
    width: 100%;
    height: 100%;
    border: none;
    background-color: none;

}
.searchBar input:focus{
    width: 100%;
    height: 100%;
    background-color: none;

}

*/