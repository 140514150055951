.searchresult{
    background-color: none;
    color: white;
    background-color: blue;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    padding-top: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-family: "Helvetica";
    font-weight: 100;
    text-transform: capitalize;
    background-color: blue;
    justify-content: center;
    align-items: start;

}

.searchtitle{
    flex: 100%;
    padding-left: 3em;
    font-family: "Hoefler Text";
    font-weight: 500;
    display: flex;
    justify-content: start;
    align-items: start;
    max-width: 100vw;
    top: 0;
    margin: 0;
    padding-bottom: 1em;
}
.text{
    flex: 50%;
    text-align: left;
    font-size: 35px;
}
.exit{
    flex: 50%;
    text-align: right;
    padding-right: 3em;
    font-size: 35px;
}
.null{
    text-align: center;
    padding: 0;
    font-family: "Hoefler Text";
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    flex: 100%;
    margin-bottom: 5em;
}

.searchGrid{
    margin-bottom: 5em;
    max-width: 60vw;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    grid-gap: 1em;
    border: solid white 4px;
    border-radius: 1em;
    padding: 2em;
    height: auto;
    background-color: blue;
    overflow: scroll;
}


.grid1{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: .3fr .6fr .3fr;
    padding: 1em;
    gap: 0;
    min-height: 50vh;
    max-height: auto;
    grid-gap: 1em;

}
.grid2{
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: .2fr .6fr .2fr;
    padding: 0em;
    height: 50vh;
    grid-gap: .3em;
}
.grid3{
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: .2fr .6fr .2fr;
    padding: 0em;
    height: 50vh;
    grid-gap: .3em;
}

.secondColumn{
    padding-top: .23em;
    padding-bottom: .23em;
    display: flex;
    justify-content: left;
    align-items: left;
    flex-wrap: wrap;
    height: 30vh;
    border-top: solid white .8em;
    border-bottom: solid white .8em;

}
.secondColumn p2{
    font-weight: 100;
    font-size: 3em;

}
.thirdColumn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
    border-top: solid white .8em;
    border-bottom: solid white .8em;
    padding: .3em;
}
.name{
    font-size: 40px;
    font-weight: 900;
    text-decoration: none;
    flex: 100%;
    background-color: none;
}
.servingS{
    font-size: 10px;
    font-weight: 100;
    flex: 100%;
    border-top: solid white .1em;
    border-bottom: solid white .1em;
    padding: 1em;
}

.servingS input{
    background-color: rgb(255, 255, 255);
    border: 0px;
    text-align: center;
    margin: .5em;
    padding: .5em;
    font-size: 1em;
    border-radius: 3%;
    width: fit-content;
    color: blue;
	font-family: "Andale Mono", AndaleMono, monospace;
    font-weight: 500;

}

.servingS input::placeholder{
    color: rgb(149, 149, 149);
}



.serving{
    font-size: 15px;
    font-weight: 900;
    flex: 100%;
}
.calorieS{
    flex: 100%;
    background-color: none;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    padding: .3em;
}
.calorieS p1{
    font-size: 15px;
    font-weight: 900;
    flex: 50%;
    background-color: none;
    text-align: left;
}
.calorieS p2{
    flex: 50%;
    font-size: 10px;
    font-weight: 900;
    text-transform: lowercase;
    background-color: none;
    text-align: left;
}
.calorieS p3{
    flex: 50%;
    font-size: 30px;
    font-weight: 900;
    text-transform: lowercase;
    background-color: none;
    text-align: right;
    padding-right: .5em;
}
.proteinS{
    flex: 100%;
    background-color: none;
    display: flex;
    align-items: center;
    padding: .2em;
}
.proteinS p1{
    font-size: 15px;
    font-weight: 900;
    background-color: none;
}
.proteinS p2{
    font-size: 15px;
    background-color: none;
    text-align: center;
    text-transform: lowercase;
    margin-left: .3em;
}
.fatS{
    flex: 100%;
    background-color: none;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.fatS h1{
    font-size: 15px;
    font-weight: 900;
    background-color: none;
}
.fatS p1{
    font-size: 15px;
    background-color: none;
}
.fatS p2{
    font-weight: 100;
    font-size: 15px;
    margin-left: .5em;
    text-transform: lowercase;
}
.totalfat{
    flex: 100%; 
    border-bottom: solid white .1em;
    display: flex;
    align-items: center;
}
.totalfat p1{
    font-weight: 900;
    font-size: 20px;
}
.totalfat p2{
    font-size: 20px;
}

.sat{
    padding-left: 1em;
    flex: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid white .1em;
}
.mono{
    padding-left: 1em;
    flex: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid white .1em;
}
.poly{
    padding-left: 1em;
    flex: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid white .1em;
}
.chol{
    font-weight: 900;
    flex: 100%;
    display: flex;
    align-items: center;
}
.trans{
    padding-left: 1em;
    font-style: italic;
    flex: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid white .1em;
}

.carbS{
    flex: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: solid white .1em;
}
.carbS p1{
    font-size: 20px;
    font-weight: 900;
    background-color: none;
    text-align: left;
}
.carbS p2{
    text-align: left;
    font-size: 20px;
    text-transform: lowercase;
    margin-left: .3em;


}
.totalcarb{
    flex: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid white .1em;
    padding: .2em;
}
.fiber{
    flex: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid white .1em;
    padding: .3em;
}
.fiber p1{

    padding-left: 1em;
    font-size: 15px;
    font-weight: 100;
}
.fiber p2{
    font-size: 15px;
    margin-left: .3em;
    text-transform: lowercase;
}
.sugar{
    flex: 100%;
    display: flex;
    align-items: center;
    padding: .3em;
}
.sugar p1{
    padding-left: 1em;
    font-weight: 100;  
    font-size: 15px;
}
.sugar p2{
    font-weight: 100;  
    font-size: 15px;
}
.sodium{
    flex: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid white .1em;
    padding: .2em;
}
.sodium p1{
    font-size: 15px;
    font-weight: 900;
    background-color: none;
    text-align: left;
}
.sodium p2{
    font-size: 15px;
    text-transform: lowercase;
    margin-left: .3em;
}
.caffeine{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.caffeine p1{
    font-size: 15px;
    font-weight: 900;
    background-color: none;
    text-align: center;
    flex: 50%;
}
.caffeine p2{
    font-size: 15px;
    flex: 50%;
    text-transform: lowercase;
}

.vitaminS{ /*lknfdkjbnvkjdbvkjbdfkvbjkjfv*/
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    padding: 0em;
    font-weight: 100;
    grid-gap: .3em;
}
.vitaminS p1{
    font-weight: 500;
    font-size: .75em;
    background-color: none;
}
.vitaminS p2{
    font-size: .75em;
    text-transform: lowercase;
    margin: .3em;
}
.mineralS{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    padding: 0em;
    font-weight: 100;
    grid-gap: .3em;

}
.mineralS p1{
    font-size: .75em;
    font-weight: 500;
    background-color: none;
}
.mineralS p2{
    font-size: .75em;
    text-transform: lowercase;
    margin-left: .3em;
}

.ingredients p1{
    font-size: 15px;
    font-weight: 900;
}
.ingredients p2{
    font-size: 15px;
    margin-left: .3em;
}


@media screen and (max-width: 800px)
{

    html, body { 
    margin: 0;
    padding: 0;
    zoom: 1;
    background-color: blue;
    }
    #root{
        margin: 0;
        padding: 0;
    }

    .searchresult{
        color: white;
        background-color: blue;
        height: 100vh;
        width: 100vw;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: start;

    }

    .searchtitle{
        flex: 100%;
        margin-bottom: 0;
        padding-left: 2em;
        font-family: "Hoefler Text";
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100vw;
        top: 0;
        padding-bottom: 1em;
    }
    .text{
        flex: 50%;
        text-align: left;
        font-size: 30px;
        font-weight: 500;
    }
    .exit{
        flex: 50%;
        text-align: right;
        padding-right: 2em;
        font-size: 25px;
    }
    .null{
        margin-bottom: 3em;
        padding: 1em;
    }

    .searchGrid{
        margin-bottom: 5em;
        max-width: 60vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 2em;
        border: solid white 4px;
        border-radius: 1em;
        padding: 1.5em;
        height: auto;
        background-color: blue;
        overflow: hidden;
    }

    .grid1{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        padding: 1em;
        gap: 0;
        min-height: auto;
        max-height: auto;
        grid-gap: 1em;

    }
    .grid2{
        padding: 1em;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: .2fr .6fr .2fr;
        padding: 0em;
        height: 50vh;
        grid-gap: .3em;
    }
    .grid3{
        padding: 1em;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: .2fr .6fr .2fr;
        padding: 0em;
        height: 50vh;
        grid-gap: .3em;
    }

    .secondColumn{
        padding-top: .23em;
        padding-bottom: .23em;
        display: flex;
        justify-content: left;
        align-items: left;
        flex-wrap: wrap;
        height: 30vh;
        border-top: solid white .8em;
        border-bottom: solid white .8em;

    }
    .secondColumn p2{
        font-weight: 100;
        font-size: 3em;

    }
    .thirdColumn{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        height: auto;
        border-top: solid white .8em;
        border-bottom: solid white .8em;
        padding: .3em;
    }
    .name{
        font-size: 40px;
        font-weight: 900;
        text-decoration: none;
        flex: 100%;
        background-color: none;
    }
    .servingS{
        font-size: 10px;
        font-weight: 100;
        flex: 100%;
        border-top: solid white .1em;
        border-bottom: solid white .1em;
        padding: 1em;
    }

    .serving{
        font-size: 15px;
        font-weight: 900;
        flex: 100%;
    }
    .calorieS{
        flex: 100%;
        background-color: none;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        padding: .3em;
    }
    .calorieS p1{
        font-size: 15px;
        font-weight: 900;
        flex: 50%;
        background-color: none;
        text-align: left;
    }
    .calorieS p2{
        flex: 50%;
        font-size: 10px;
        font-weight: 900;
        text-transform: lowercase;
        background-color: none;
        text-align: left;
    }
    .calorieS p3{
        flex: 50%;
        font-size: 30px;
        font-weight: 900;
        text-transform: lowercase;
        background-color: none;
        text-align: right;
        padding-right: .5em;
    }
    .proteinS{
        flex: 100%;
        background-color: none;
        display: flex;
        align-items: center;
        padding: .2em;
    }
    .proteinS p1{
        font-size: 15px;
        font-weight: 900;
        background-color: none;
    }
    .proteinS p2{
        font-size: 15px;
        background-color: none;
        text-align: center;
        text-transform: lowercase;
        margin-left: .3em;
    }
    .fatS{
        flex: 100%;
        background-color: none;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
    }

    .fatS h1{
        font-size: 15px;
        font-weight: 900;
        background-color: none;
    }
    .fatS p1{
        font-size: 15px;
        background-color: none;
    }
    .fatS p2{
        font-weight: 100;
        font-size: 15px;
        margin-left: .5em;
        text-transform: lowercase;
    }
    .totalfat{
        flex: 100%; 
        border-bottom: solid white .1em;
        display: flex;
        align-items: center;
    }
    .totalfat p1{
        font-weight: 900;
        font-size: 20px;
    }
    .totalfat p2{
        font-size: 20px;
    }

    .sat{
        padding-left: 1em;
        flex: 100%;
        display: flex;
        align-items: center;
        border-bottom: solid white .1em;
    }
    .mono{
        padding-left: 1em;
        flex: 100%;
        display: flex;
        align-items: center;
        border-bottom: solid white .1em;
    }
    .poly{
        padding-left: 1em;
        flex: 100%;
        display: flex;
        align-items: center;
        border-bottom: solid white .1em;
    }
    .chol{
        font-weight: 900;
        flex: 100%;
        display: flex;
        align-items: center;
    }
    .trans{
        padding-left: 1em;
        font-style: italic;
        flex: 100%;
        display: flex;
        align-items: center;
        border-bottom: solid white .1em;
    }

    .carbS{
        flex: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: solid white .1em;
    }
    .carbS p1{
        font-size: 20px;
        font-weight: 900;
        background-color: none;
        text-align: left;
    }
    .carbS p2{
        text-align: left;
        font-size: 20px;
        text-transform: lowercase;
        margin-left: .3em;


    }
    .totalcarb{
        flex: 100%;
        display: flex;
        align-items: center;
        border-bottom: solid white .1em;
        padding: .2em;
    }
    .fiber{
        flex: 100%;
        display: flex;
        align-items: center;
        border-bottom: solid white .1em;
        padding: .3em;
    }
    .fiber p1{

        padding-left: 1em;
        font-size: 15px;
        font-weight: 100;
    }
    .fiber p2{
        font-size: 15px;
        margin-left: .3em;
        text-transform: lowercase;
    }
    .sugar{
        flex: 100%;
        display: flex;
        align-items: center;
        padding: .3em;
    }
    .sugar p1{
        padding-left: 1em;
        font-weight: 100;  
        font-size: 15px;
    }
    .sugar p2{
        font-weight: 100;  
        font-size: 15px;
    }
    .sodium{
        flex: 100%;
        display: flex;
        align-items: center;
        border-bottom: solid white .1em;
        padding: .2em;
    }
    .sodium p1{
        font-size: 15px;
        font-weight: 900;
        background-color: none;
        text-align: left;
    }
    .sodium p2{
        font-size: 15px;
        text-transform: lowercase;
        margin-left: .3em;
    }
    .caffeine{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .caffeine p1{
        font-size: 15px;
        font-weight: 900;
        background-color: none;
        text-align: center;
        flex: 50%;
    }
    .caffeine p2{
        font-size: 15px;
        flex: 50%;
        text-transform: lowercase;
    }

    .vitaminS{ /*lknfdkjbnvkjdbvkjbdfkvbjkjfv*/
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto auto;
        padding: 0em;
        font-weight: 100;
        grid-gap: .3em;
    }
    .vitaminS p1{
        font-weight: 500;
        font-size: .75em;
        background-color: none;
    }
    .vitaminS p2{
        font-size: .75em;
        text-transform: lowercase;
        margin: .3em;
    }
    .mineralS{
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
        padding: 0em;
        font-weight: 100;
        grid-gap: .3em;

    }
    .mineralS p1{
        font-size: .75em;
        font-weight: 500;
        background-color: none;
    }
    .mineralS p2{
        font-size: .75em;
        text-transform: lowercase;
        margin-left: .3em;
    }

    .ingredients p1{
        font-size: 15px;
        font-weight: 900;
    }
    .ingredients p2{
        font-size: 15px;
        margin-left: .3em;
    }

}

/* Hide scrollbar for Chrome, Safari and Opera */
.searchresult::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .searchresult{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
