.ErrorScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: blue;
    width: 100vw;
    height: 100vh;
    text-align: center;
    flex-wrap: wrap;
    flex-direction: row; /* Optional, to ensure row wrap */
}

.ErrorMessage {
    font-family: "Hoefler Text";
    font-size: 1.3em;
    font-weight: 500;
    color: white;
    padding: 2em;
    width: 100%; /* Makes the message take up full width to force wrapping */
    box-sizing: border-box;
}

button {
    background-color: blue;
    color: white;
    font-family: "Hoefler Text";
    font-weight: 500;
    padding: 1em;
    border: 0px;
    width: 100%; /* Ensures the button also wraps */
    box-sizing: border-box;
}

button:hover{
    color: rgb(215, 215, 215);
}
