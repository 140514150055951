html, body {
  margin: 0;
  padding: 0;
}

.LogIn {
  background-color: blue;
  min-width: 100vw;
  min-height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginbox {
  justify-content: center;
  align-items: start;
  border-radius: 2em;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 25vw;
  border: solid .1em white;
  padding: 1em;
  background-color: blue;
  animation: fadeIn 3s;
}

.loginbox h1 {
  color: rgb(255, 255, 255);
  font-family: "Hoefler Text", serif;
  font-size: 2em;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  padding: 1em;
}

.loginbox h1:hover {
  opacity: .8;
}

.loginbox input {
  background-color: blue;
  color: white;
  border: none;
  border-bottom: solid white 2px;
  font-family: "Andale Mono", AndaleMono, monospace;
  font-size: 16px;
  font-weight: light;
  max-width: 70%;
  margin-bottom: .5em;
  padding: 1em;
  text-align: left;
  flex: 100%;
}

.loginbox input:focus {
  outline: none;
}

.loginbox input::placeholder {
  text-align: left;
  font-style: italic;
  opacity: .8;
  color: white;
  text-transform: capitalize;
}

.loginbox button {
  text-align: center;
  text-transform: uppercase;
  margin: 3em;
  border: solid white 1.5px;
  padding: .5em;
  border-radius: .5em;
  font-family: "Andale Mono", AndaleMono, monospace;
  background-color: blue;
  color: white;
  flex: 100%;
  max-width: 7vw;
}

.loginbox button:active {
  color: blue;
  background-color: aliceblue;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media screen and (max-width: 1100px) {
  .loginbox {
    max-width: 80vw;
    border: none;
  }
  .loginbox h1 {
    font-size: 2.5em;
    padding-bottom: .5em;
  }
  .loginbox button {
    max-width: 25vw;
  }
  .loginbox input {
    max-width: 80%;
  }
}